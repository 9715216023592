import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';

import { environment } from '../../../environments/environment';

import { MessageService } from '../../@pages/components/message/message.service';

import 'rxjs/add/operator/toPromise';

@Injectable()
export class IdeateHelper {

	public config = environment;
	public siteToken = null;

	constructor(
		private location: Location,
		private httpClient: HttpClient,
		private notification: MessageService
	) { }

	tDuration(totalSecs: string | number = '0') {
		totalSecs = totalSecs.toString();
		const sec_num = parseInt(totalSecs, 10); // don't forget the second param
		const hours = Math.floor(sec_num / 3600);
		const minutes = Math.floor((sec_num - (hours * 3600)) / 60);
		const seconds = sec_num - (hours * 3600) - (minutes * 60);
		return this.pad(hours, 2) + ':' + this.pad(minutes, 2) + ':' + this.pad(seconds, 2);
	}
	tFormatFromStr(time: string | any[]) {
		time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
		if (time.length > 1) {
			time = time.slice(1, -1);
			time[3] = +time[0] < 12 ? ' AM' : ' PM';
			time[0] = +time[0] % 12 || 12;
		}
		time[0] = this.pad(+time[0], 2);
		time[2] = this.pad(+time[2], 2);
		return time.join('');
	}
	dFormatFromStr(strDate = '0000-00-00', dateSeparator = '-', defaultVal = 'N/A') {
		if (strDate !== '0000-00-00') {
			const objDate = new Date(strDate);
			const formattedDate = this.pad((objDate.getUTCMonth() + 1), 2) + dateSeparator + this.pad(objDate.getUTCDate(), 2) + dateSeparator + objDate.getUTCFullYear();
			return formattedDate;
		}
		return defaultVal;
	}
	dtFormatFromStr(strDateTime = '0000-00-00 00:00:00', dateSeparator = '-', defaultVal = 'N/A') {
		let formattedDateTime = defaultVal;
		if (strDateTime !== '0000-00-00 00:00:00') {
			const arrDateTime = strDateTime.split(' ');
			if (arrDateTime.length === 2) {
				formattedDateTime = this.dFormatFromStr(arrDateTime[0], dateSeparator) + ' ' + this.tFormatFromStr(arrDateTime[1]);
			}
		}
		return formattedDateTime;
	}
	dtFormatFromObj(objDate: Date) {
		return this.dtFormatFromStr(this.dtFormatToDB(objDate));
	}
	dtFormatFromStrToObj(strDateTime: string) {
		return new Date(Date.parse(strDateTime.replace(/[-]/g, '/')));
	}
	dFormatToDB(objDate: Date | string) {
		let strDate = '';
		if (objDate instanceof Date || (typeof objDate === 'string' && objDate.split(' ').join('') !== '')) {
			objDate = (objDate instanceof Date) ? objDate : new Date(objDate);
			if (objDate instanceof Date) {
				strDate += objDate.getFullYear() + '-' + this.pad((objDate.getMonth() + 1), 2) + '-' + this.pad(objDate.getDate(), 2);
			}
		}
		return strDate.trim();
	}
	tFormatToDBFrom24HoursFormat(time: string) {
		let hours = Number(time.match(/^(\d+)/)[1]);
		const minutes = Number(time.match(/:(\d+)/)[1]);
		const AMPM = time.match(/\s(.*)$/)[1];
		if (AMPM === 'PM' && hours < 12) {
			hours = hours + 12;
		}
		if (AMPM === 'AM' && hours === 12) {
			hours = hours - 12;
		}
		let sHours = hours.toString();
		let sMinutes = minutes.toString();
		if (hours < 10) {
			sHours = '0' + sHours;
		}
		if (minutes < 10) {
			sMinutes = '0' + sMinutes;
		}
		const sTime = sHours + ':' + sMinutes;
		return sTime;
	}
	tFormatToDB(objTime: Date | string) {
		let strTime = '';
		if (objTime instanceof Date || (typeof objTime === 'string' && objTime.split(' ').join('') !== '')) {
			objTime = (objTime instanceof Date) ? objTime : new Date(objTime);
			if (objTime instanceof Date) {
				strTime += this.pad(objTime.getHours(), 2) + ':' + this.pad(objTime.getMinutes(), 2) + ':' + this.pad(objTime.getSeconds(), 2);
			}
		}
		return strTime.trim();
	}
	dtFormatToDB(objDate: Date | string, objTime: Date | Boolean = false) {
		let strDateTime = '';
		if (objDate instanceof Date || (typeof objDate === 'string' && objDate.split(' ').join('') !== '')) {
			objDate = (objDate instanceof Date) ? objDate : new Date(objDate);
			strDateTime = this.dFormatToDB(objDate) + ' ';
			if (objTime instanceof Date) {
				strDateTime += this.tFormatToDB(objTime);
			} else {
				strDateTime += this.tFormatToDB(objDate);
			}
		}
		return strDateTime.trim();
	}

	getArrayIndex(array: Array<any>, key: any, value: any) {
		for (let i = 0; i < array.length; i++) {
			if (array[i][key] === value) {
				return i;
			}
		}
		return false;
	}

	getCurrentTimestamp() {
		return Math.round(Date.parse(new Date().toUTCString()) / 1000);
	}

	getHttpError(error: HttpErrorResponse | any) {
		let errMsg = 'Invalid Request!';
		if (error instanceof ErrorEvent) {
			if (this.getObjVal(error, ['status']) === 0) {
				errMsg = 'Failed to connect to server.';
			} else {
				errMsg = `HTTP_ERROR ${this.getObjVal(error, ['status'], '')} : ${this.getObjVal(error, ['statusText'], errMsg)}`;
			}
		} else {
			if (this.getObjVal(error, ['status']) === 0) {
				errMsg = 'Failed to connect to server.';
			} else {
				errMsg = `HTTP_ERROR ${this.getObjVal(error, ['status'], '')} : ${this.getObjVal(error, ['statusText'], errMsg)}`;
			}
		}
		error.errorMessage = errMsg;
		return error;
	}

	getObjVal(obj: any, properties = [], defaultVal: any = '') {
		if (this.isEmptyObj(obj)) {
			return defaultVal;
		} else {
			if (properties.length >= 1) {
				const property = properties.shift();
				if (typeof obj[property] === 'undefined' || obj[property] == null) {
					return defaultVal;
				} else {
					if (properties.length > 0) {
						return this.getObjVal(obj[property], properties, defaultVal);
					} else {
						return obj[property];
					}
				}
			} else {
				return defaultVal;
			}
		}
	}

	getTimeAgo(ts: any) {
		const periods = ['second', 'minute', 'hour', 'day', 'week', 'month', 'year', 'decade'];
		const lengths = [60, 60, 24, 7, 4.35, 12, 10];
		const currTS = Math.round(Date.parse(new Date().toUTCString()) / 1000);
		let difference = Math.floor(currTS - ts);
		let j;
		for (j = 0; difference >= lengths[j]; j++) {
			difference /= lengths[j];
		}
		difference = Math.floor(difference);
		if (difference !== 1) {
			periods[j] += 's';
		}
		if (difference <= 10 && periods[j].indexOf('second') === 0) {
			return 'Just now';
		} else {
			return difference + ' ' + periods[j] + ' ago';
		}
	}

	showNotification(type: string = '', message: string = '', title: string = '', duration = 5000) {
		try {
			return this.notification.create(
				type, // info/warning/success/danger/default
				message,
				{
					Title: title,
					Position: 'bottom-right',
					Style: 'simple',
					Duration: duration
				}
			);
		} catch (e) {
			let plainTextMessage = title;
			plainTextMessage += (title !== '') ? '\n' : '';
			plainTextMessage += message;
			alert(plainTextMessage);
			return false;
		}
	}

	removeNotification(id: string) {
		this.notification.remove(id);
	}

	isValidJSON(strJSON: string) {
		try {
			JSON.parse(strJSON);
		} catch (e) {
			return false;
		}
		return true;
	}

	isEmptyObj(obj: any = {}) {
		return (obj === null || typeof obj !== 'object' || Object.keys(obj).length === 0);
	}

	baseName(path: string) {
		const base = path.substring(path.lastIndexOf('/') + 1);
		return base;
	}

	getExtension(fileName: string) {
		const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
		return fileExtension;
	}

	readFile(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = res => {
				if (typeof (reader.result) === 'string') {
					resolve(reader.result.split(',')[1]);
				} else {
					resolve(reader.result);
				}
			};
			reader.onerror = err => reject(err);
		});
	}

	pad(num: number, size: number = 1) {
		let str: string = num + '';
		while (str.length < size) {
			str = '0' + str;
		}
		return str;
	}

	objToFormData(obj: Object, form?: FormData, namespace?: string): FormData {
		const formData = form || new FormData();
		for (const property in obj) {
			if (!obj.hasOwnProperty(property)) { // (|| !obj[property])
				continue;
			}
			const formKey = namespace ? `${namespace}[${property}]` : property;
			if (obj[property] instanceof Date) {
				formData.append(formKey, obj[property].toISOString());
			} else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
				this.objToFormData(obj[property], formData, formKey);
			} else {
				formData.append(formKey, obj[property]);
			}
		}
		return formData;
	}

	makeAPIRequest(apiEndPoint: string, params: any = {}, method = 'post', postDataAsRequestBody = false, headers: any = {}): Promise<any> {
		apiEndPoint = (apiEndPoint.startsWith('http:') || apiEndPoint.startsWith('https:')) ? apiEndPoint : this.config.powerRooterAPI + apiEndPoint;
		if (method === 'get') {
			const httpParams = new URLSearchParams();
			for (const name in params) {
				if (params.hasOwnProperty(name)) {
					httpParams.set(name, params[name]);
				}
			}
			const paramsQS = httpParams.toString();
			return this.httpClient.get(apiEndPoint + '?' + paramsQS).toPromise().then((responseJson: any) => {
				return Promise.resolve(responseJson || '');
			}).catch((error) => {
				return Promise.reject(this.getHttpError(error));
			});
		} else if (method === 'post') {
			// add siteToken to every request
			let siteTokenObj = {site_token: this.siteToken};
			if (params instanceof FormData) {
				params.append('site_token', this.siteToken);
			} else {
				params = {...params, ...siteTokenObj}; // For non-FormData params
			}

			let reqParams: any = this.objToFormData(params);
			if (postDataAsRequestBody) {
				reqParams = JSON.stringify(params);
			}
			// headers['Accept'] = 'application/json';
			// headers['Content-Type'] = 'multipart/form-data';
			const httpOptions = { headers: new HttpHeaders(headers) };
			return this.httpClient.post(apiEndPoint, reqParams, httpOptions).toPromise().then((responseJson: any) => {
				return Promise.resolve(responseJson || '');
			}).catch((error) => {
				return Promise.reject(this.getHttpError(error));
			});
		} else {
			return Promise.reject(this.getHttpError({}));
		}
	}

	ucFirst(str: string = '') {
		str = (str.length > 0) ? str.charAt(0).toUpperCase() + str : str;
		str = (str.length > 1) ? str.charAt(0) + str.slice(2) : str;
		return str;
	}

	ucWords(str: string = '') {
		return (str + '').replace(/^(.)|\s+(.)/g, function ($1) {
			return $1.toUpperCase();
		});
	}

	normalizeString(str: string = '') {
		return str.replace(/[-_]/g, ' ');
	}

	navBack() {
		this.location.back();
	}

}

@Injectable()
export class IdeateValidators {

	constructor(
		public helper: IdeateHelper
	) { }

	getValidationErrors(form: any, validationMessages: any, isFormSubmitted = false): any {
		if (form && Object.keys(validationMessages).length > 0) {
			for (const field in validationMessages) {
				if (validationMessages.hasOwnProperty(field)) {
					validationMessages[field].error = '';
					const control = form.get(field);
					if (control && control.enabled && (control.dirty || control.touched || isFormSubmitted) && control.invalid) {
						const messages = validationMessages[field];
						for (const key in control.errors) {
							if (control.errors.hasOwnProperty(key)) {
								validationMessages[field].error += (validationMessages[field].error !== '') ? '<br/>' : '';
								validationMessages[field].error += messages[key];
								break;
							}
						}
					}
				}
			}
		}
		return validationMessages;
	}

	equalTo(matchControl: string) {
		let control1: FormControl;
		let control2: FormControl;
		return function matchOtherControlValue(control: FormControl) {
			if (!control.parent) {
				return null;
			}
			if (!control1) {
				control1 = control;
				control2 = control.parent.get(matchControl) as FormControl;
				if (!control2) {
					throw new Error('equalToValidator(): Second control is not found in parent group');
				}
				control2.valueChanges.subscribe(() => {
					control1.updateValueAndValidity();
				});
			}
			if (!control2) {
				return null;
			}
			if (control2.value !== control1.value) {
				return {
					equalTo: { valid: false }
				};
			}
			return null;
		};
	}

	slug(c: FormControl) {
		const USERNAME_REGEXP = /^[a-z0-9_-]+$/i;
		return (!c.value || USERNAME_REGEXP.test(c.value)) ? null : {
			slug: { valid: false }
		};
	}

	url(c: FormControl) {
		const URL_REGEXP = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/i;
		return (!c.value || URL_REGEXP.test(c.value)) ? null : {
			url: { valid: false }
		};
	}

	strongPassword(c: FormControl) {
		return (
			c.value === '' || (
				/^[A-Za-z0-9\d`\-=\\\/;',.~!@#$%^&*()_+{}|:"<>?]*$/.test(c.value) // consists of only these
				&& /[A-Z]/.test(c.value) // has an uppercase letter
				&& /[a-z]/.test(c.value) // has a lowercase letter
				&& /\d/.test(c.value) // has a digit
				&& /[`\-=\\\/;',.~!@#$%^&*()_+{}|:"<>?]/.test(c.value) // has a symbol
			)
		) ? null : {
			strongPassword: { valid: false }
		};
	}

	remote(apiEndPoint: string, mainParamName: string, params: any = {}) {
		let debounceTimer;
		const helper = this.helper;
		return (formControl: FormControl) => {
			return new Promise((resolve) => {
				if (debounceTimer) {
					clearTimeout(debounceTimer);
				}
				params[mainParamName] = formControl.value;
				debounceTimer = setTimeout(() => {
					return helper.makeAPIRequest(apiEndPoint, params).then((response) => {
						if (response.success === 1) {
							return resolve(null);
						} else {
							return resolve({
								'remote': { valid: false }
							});
						}
					}).catch(() => {
						return resolve({
							'remote': { valid: false }
						});
					});
				}, 500);
			});
		};
	}

	allowedType(types: string[]) {
		return function matchFileType(fileFormControl: FormControl) {
			for (const file in fileFormControl.value) {
				if (fileFormControl.value.hasOwnProperty(file)) {
					if (types.indexOf(fileFormControl.value[file].type) === -1) {
						return {
							allowedType: { valid: false }
						};
					}
				}
			}
			return null;
		};
	}

}
