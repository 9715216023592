import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-site-selector',
	templateUrl: './site-selector.component.html',
	styleUrls: ['./site-selector.component.scss']
})
export class SiteSelectorComponent implements OnInit {

	@Input() sites: any = [];
	@Output() siteSelected = new EventEmitter<{ id: number; name: string; url?: string }>();

	constructor() { }

	ngOnInit() { }

	selectSite(site) {
		this.siteSelected.emit(site);
	}
}
