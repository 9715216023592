
import { Component, OnInit, OnDestroy, ViewChild, Input, HostListener } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { pagesToggleService } from '../../services/toggler.service';
import { Router, Event, NavigationEnd } from '@angular/router';
declare var pg: any;

import { IdeateHelper } from '../../../core/services/ideate';
import { App } from '../../../providers/app';
import { Account } from '../../../providers/account';

@Component({
	selector: 'root-layout',
	templateUrl: './root.component.html',
	styleUrls: ['./root.component.scss'],
})
export class RootLayout implements OnInit, OnDestroy {

	@ViewChild('root') root;
	layoutState: string;
	extraLayoutClass: string;
	_boxed = false;
	_menuPin = false;
	_enableHorizontalContainer: boolean;
	_pageContainerClass = '';
	_contentClass = '';
	_footer = true;
	hasMessageCenter = false;
	_messageCenter = false;
	_menuDrawerOpen = false;
	// Mobile
	_mobileSidebar = false;
	// Mobile
	_mobileHorizontalMenu = false;
	_pageTitle: string;
	// Sub layout - eg: email
	_layoutOption: string;
	_subscriptions: Array<Subscription> = [];;
	_layout;
	@Input()
	public contentClass = '';

	@Input()
	public pageWrapperClass = '';

	@Input()
	public footer = true;

	constructor(
		public toggler: pagesToggleService,
		private router: Router,
		public helper: IdeateHelper,
		public app: App,
		public account: Account
	) {
		if (this.layoutState) {
			pg.addClass(document.body, this.layoutState);
		}
		router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				let root = this.router.routerState.snapshot.root;
				while (root) {
					if (root.children && root.children.length) {
						root = root.children[0];
					} else if (root.data) {
						// Custom Route Data here
						this._pageTitle = root.data['title'];
						this._layoutOption = root.data['layoutOption'];
						this._boxed = root.data['boxed'];
						break;
					} else {
						break;
					}
				}
				// Reset Any Extra Layouts added from content pages
				pg.removeClass(document.body, this.extraLayoutClass);
				// Close Sidebar and Horizonta Menu
				if (this._mobileSidebar) {
					this._mobileSidebar = false;
					pg.removeClass(document.body, 'sidebar-open');
					this.toggler.toggleMobileSideBar(this._mobileSidebar);
				}
				this._mobileHorizontalMenu = false;
				this.toggler.toggleMobileHorizontalMenu(this._mobileHorizontalMenu);
				// Scoll Top
				this.scrollToTop();
			}

			// Subscribition List
			this._subscriptions.push(this.toggler.pageContainerClass
				.subscribe(state => {
					this._pageContainerClass = state;
				}));

			this._subscriptions.push(this.toggler.contentClass
				.subscribe(state => {
					this._contentClass = state;
				}));

			this._subscriptions.push(this.toggler.bodyLayoutClass
				.subscribe(state => {
					if (state) {
						this.extraLayoutClass = state;
						pg.addClass(document.body, this.extraLayoutClass);
					}
				}));

			this._subscriptions.push(this.toggler.Applayout
				.subscribe(state => {
					this.changeLayout(state);
				}));

			this._subscriptions.push(this.toggler.Footer
				.subscribe(state => {
					this._footer = state;
				}));

			this._subscriptions.push(this.toggler.MessageCenter
				.subscribe(state => {
					this._messageCenter = state;
				}));

			this._subscriptions.push(this.toggler.mobileHorizontaMenu
				.subscribe(state => {
					this._mobileHorizontalMenu = state;
				}));

			this.hasMessageCenter = this.account && this.account.siteFeatures && this.account.siteFeatures.message_center;
		});

	}

	/** @function changeLayout
	*   @description Add Document Layout Class
	*/
	changeLayout(type: string) {
		this.layoutState = type;
		if (type) {
			pg.addClass(document.body, type);
		}
	}

	/** @function removeLayout
	*   @description Remove Document Layout Class
	*/
	removeLayout(type: string) {
		pg.removeClass(document.body, type);
	}

	ngOnInit() {
	}

	ngOnDestroy() {
		for (const sub of this._subscriptions) {
			sub.unsubscribe();
		}
	}

	/** @function scrollToTop
	*   @description Force to scroll to top of page. Used on Route
	*/
	scrollToTop() {
		const top = window.pageYOffset;
		if (top === 0) {
			const scroller = document.querySelector('.page-container');
			if (scroller) {
				scroller.scrollTo(0, 0);
			}
		} else {
			window.scrollTo(0, 0);
		}
	}

	/** @function toggleMenuPin
	*   @description Permanently Open / Close Main Sidebar
	*/
	toggleMenuPin() {
		if (pg.isVisibleSm()) {
			this._menuPin = false;
			return;
		}
		if (this._menuPin) {
			pg.removeClass(document.body, 'menu-pin');
			this._menuPin = false;
		} else {
			pg.addClass(document.body, 'menu-pin');
			this._menuPin = true;
		}
	}

	/** @function toggleMenuDrawer
	*   @description Open Main Sidebar Menu Drawer - Service
	*/
	toggleMenuDrawer() {
		this._menuDrawerOpen = (this._menuDrawerOpen === true ? false : true);
		this.toggler.toggleMenuDrawer();
	}

	/** @function toggleMobileSidebar
	*   @description Open Main Sidebar on Mobile - Service
	*/
	toggleMobileSidebar() {
		if (this._mobileSidebar) {
			this._mobileSidebar = false;
			pg.removeClass(document.body, 'sidebar-open');
		} else {
			this._mobileSidebar = true;
			pg.addClass(document.body, 'sidebar-open');
		}
		this.toggler.toggleMobileSideBar(this._mobileSidebar);
	}

	/** @function toggleHorizontalMenuMobile
	*   @description Call Horizontal Menu Toggle Service for mobile
	*/
	toggleHorizontalMenuMobile() {
		this._mobileHorizontalMenu = (this._mobileHorizontalMenu === true ? false : true);
		this.toggler.toggleMobileHorizontalMenu(this._mobileHorizontalMenu);
	}

	@HostListener('window:resize', [])
	onResize() {
		this.autoHideMenuPin();
	}

	// Utils
	autoHideMenuPin() {
		if (window.innerWidth < 1025) {
			if (pg.hasClass(document.body, 'menu-pin')) {
				pg.addClass(document.body, 'menu-unpinned');
				pg.removeClass(document.body, 'menu-pin');
			}
		} else {
			if (pg.hasClass(document.body, 'menu-unpinned')) {
				pg.addClass(document.body, 'menu-pin');
			}
		}
	}

	/** @function toggleMessageCenter
	*   @description Open Message Center - Service
	*/
	toggleMessageCenter() {
		this._messageCenter = (this._messageCenter === true ? false : true);
		this.toggler.toggleMessageCenter(this._messageCenter);
	}

	public logout() {
		this.account.logOut();
	}
}
