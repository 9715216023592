import { Component, ViewChild, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { DataTableDirective } from 'angular-datatables';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { Subject } from 'rxjs';

import { IdeateHelper } from '../core/services/ideate';
import { Account } from '../providers/account';

@Component({
	selector: 'app-customers',
	templateUrl: './customers.component.html',
	styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit, OnDestroy {

	@ViewChild(DataTableDirective) dtElement: DataTableDirective;
	@ViewChild('deletePrompt') deletePrompt: ModalDirective;
	@ViewChild('datatable') dtElementRef: ElementRef;

	public dtOptions: DataTables.Settings = {};
	public dtTrigger: Subject<any> = new Subject();
	public dtColsCount = 0;
	public dtRows: any[];
	public selectedRecordID = 0;

	public filterCustomerType = '';

	constructor(
		private route: ActivatedRoute,
		public helper: IdeateHelper,
		private account: Account
	) { }

	ngOnInit() {
		this.dtColsCount = this.dtElementRef.nativeElement.children[0].children[0].children.length;
		this.route.paramMap.subscribe((params: ParamMap) => {
			this.initDataTable();
		});
	}

	onCustomersFilterUpdated() {
		this.initDataTable();
	}

	initDataTable(timeOutInterval: number = 0) {
		setTimeout(() => {
			if (this.dtElement.dtInstance) {
				this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
					dtInstance.destroy();
					this.loadDatable();
				});
			} else {
				this.loadDatable();
			}
		}, timeOutInterval);
	}

	loadDatable() {
		const that = this;
		const reqParams: any = {};
		reqParams.user_id = this.account.info.id;
		reqParams.auth_token = this.account.info.auth_token;
		reqParams.type = this.filterCustomerType;
		this.dtOptions = {
			pagingType: 'full_numbers',
			lengthMenu: [[50, 100, 200, 500, 1000], [50, 100, 200, 500, 1000]],
			pageLength: 50,
			serverSide: true,
			processing: true,
			searching: true,
			dom: 'fl"("i")"rtp',
			ajax: (dataTablesParameters: any, callback) => {
				dataTablesParameters = Object.assign(dataTablesParameters, reqParams);
				this.helper.makeAPIRequest('customers/list', dataTablesParameters).then((response) => {
					if (response.success === 1) {
						that.dtRows = response.data.datatable.rows;
						callback({
							recordsTotal: response.data.datatable.recordsTotal,
							recordsFiltered: response.data.datatable.recordsFiltered,
							data: []
						});
					} else if (response.error === 1) {
						if (response.errorCode === 2) {
							this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
							this.account.logOut();
						} else {
							this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
						}
					} else {
						this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
					}
				}).catch((httpError) => {
					this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
				});
			}
		};
		setTimeout(() => { this.dtTrigger.next(); });
	}

	delete(id: number) {
		this.deletePrompt.hide();
		if (id > 0) {
			const reqParams: any = {};
			reqParams.user_id = this.account.info.id;
			reqParams.auth_token = this.account.info.auth_token;
			reqParams.record_id = id;
			this.helper.makeAPIRequest('customers/delete', reqParams).then((response) => {
				if (response.success === 1) {
					const dtRowIdx = this.helper.getArrayIndex(this.dtRows, 'id', id);
					if (dtRowIdx !== false) {
						this.dtRows.splice(dtRowIdx, 1);
					}
				} else if (response.error === 1) {
					if (response.errorCode === 2) {
						this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
						this.account.logOut();
					} else {
						this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
					}
				} else {
					this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
				}
			}).catch((httpError) => {
				this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
			});
		}
	}

	ngOnDestroy(): void {
		this.dtTrigger.unsubscribe();
	}
}


