import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

import { IdeateHelper, IdeateValidators } from '../core/services/ideate';
import { Account } from '../providers/account';

import 'rxjs/add/operator/switchMap';

@Component({
	selector: 'app-add-edit-service',
	templateUrl: './add-edit-service.component.html',
	styleUrls: ['./add-edit-service.component.scss']
})
export class AddEditServiceComponent implements OnInit {

	public userType = 'admin';
	public frmAddEdit: FormGroup;
	public recordID = 0;
	public record: any = {};
	public validationMessages: any = {
		title: {
			required: 'Title is required.'
		},
		price: {
			required: 'Price is required.'
		},
		is_add_on: {},
	};
	public hasChecklistAccess: boolean = false;

	public serviceChecklists = [];
	public checkListValidationMsg = '';

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		public helper: IdeateHelper,
		private customValidators: IdeateValidators,
		private account: Account
	) { }

	ngOnInit() {
		this.route.paramMap.subscribe((params: ParamMap) => {
			this.userType = params.get('type') ? params.get('type') : this.userType;
			this.recordID = (params.get('id')) ? +params.get('id') : this.recordID;
			this.getRecordData();
		});

		this.hasChecklistAccess = this.account.siteFeatures && this.account.siteFeatures.checklist;

		this.initForm();
	}

	getRecordData() {
		if (this.recordID === 0) {
			this.initForm();
		} else {
			const reqParams: any = {};
			reqParams.user_id = this.account.info.id;
			reqParams.auth_token = this.account.info.auth_token;
			reqParams.record_id = this.recordID;
			this.helper.makeAPIRequest('services/get', reqParams).then((response) => {
				if (response.success === 1) {
					this.record = response.data.record;
					this.initForm();
					this.record = response.data.record;
					if (this.hasChecklistAccess && this.record.checklist) {
						this.serviceChecklists = this.record.checklist;
					}
				} else if (response.error === 1) {
					if (response.errorCode === 2) {
						this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
						this.account.logOut();
					} else if (response.errorCode === 4) {
						this.helper.showNotification('danger', this.helper.config.defaultNoResultErrorMsg, this.helper.config.defaultErrorTitle);
						this.helper.navBack();
					} else {
						this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
					}
				} else {
					this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
				}
			}).catch((httpError) => {
				this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
			});
		}
	}

	initForm() {
		this.frmAddEdit = this.formBuilder.group({
			title: [this.record.title, Validators.compose([Validators.required])],
			price: [this.record.price, Validators.compose([Validators.required])],
			is_add_on: [+this.record.is_add_on],
		});
		this.frmAddEdit.valueChanges.subscribe((data) => {
			this.validateForm();
		});
		this.validateForm();
	}

	validateForm() {
		this.validationMessages = this.customValidators.getValidationErrors(this.frmAddEdit, this.validationMessages);
	}

	save(timeOutInterval: number = 0) {
		setTimeout(() => {
			if (this.frmAddEdit.valid) {
				const userInputs: any = this.frmAddEdit.value;
				const reqParams: any = {};
				reqParams.user_id = this.account.info.id;
				reqParams.auth_token = this.account.info.auth_token;
				reqParams.record_id = this.recordID;
				reqParams.title = userInputs.title;
				reqParams.price = (userInputs.price) ? userInputs.price.toString().replace(/[^\d.-]/g, '') : 0;
				reqParams.is_add_on = userInputs.is_add_on;
				if (this.hasChecklistAccess) {
					reqParams.checklists = JSON.stringify(this.serviceChecklists);
				}

				this.helper.makeAPIRequest('services/save', reqParams).then((response) => {
					if (response.success === 1) {
						this.helper.showNotification('success', 'Service info saved!', this.helper.config.defaultSuccessTitle);
						this.helper.navBack();
					} else if (response.error === 1) {
						if (response.errorCode === 2) {
							this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
							this.account.logOut();
						} else {
							this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
						}
					} else {
						this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
					}
				}).catch((httpError) => {
					this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
				});
			}
		}, timeOutInterval);
	}

	checklistAction(action = 'add', checkListIndex = 0) {
		if (action == 'add') {
			this.serviceChecklists.push({
				id: 0,
				title: '',
				type: 'text',
				options: []
			});
		} else {
			this.serviceChecklists.splice(checkListIndex, 1);
		}
	}

	checkListOptionAction(action = 'add', checkListIndex = 0, checklistOptionIndex = 0) {
		if (action == 'add') {
			this.serviceChecklists[checkListIndex].options.push({
				label: '',
				additional: 0
			});
		} else {
			if (this.serviceChecklists[checkListIndex].options.length > 1) {
				this.serviceChecklists[checkListIndex].options.splice(checklistOptionIndex, 1);
			}
		}
	}

	checkListOnChange(type, checkListIndex) {
		this.serviceChecklists[checkListIndex].type = type;
		if (type == 'checkbox') {
			this.serviceChecklists[checkListIndex].options = [
				{
					label: '',
					additional: 0
				}
			];
		} else {
			this.serviceChecklists[checkListIndex].options = [];
		}
	}

	checkListValidate() {
		return new Promise(resolve => {
			this.checkListValidationMsg = '';
			this.serviceChecklists.map((v) => {
				if (v.title == '') {
					this.checkListValidationMsg = 'Checklist title required.';
					return resolve(false);
				}
				if (v.type == '') {
					this.checkListValidationMsg = 'Checklist type required.';
					return resolve(false);
				}
				if (v.type == 'checkbox') {
					v.options.map((v2) => {
						if (v2.label == '') {
							this.checkListValidationMsg = 'Checklist option label required.';
							return resolve(false);
						}
					});
				}
			});
			return resolve(true);
		});
	}

	checkListTitleChange(title, checkListIndex) {
		this.serviceChecklists[checkListIndex].title = title;
	}

	optionOnChange(label, checkListIndex, optionIndex) {
		this.serviceChecklists[checkListIndex].options[optionIndex].label = label;
	}

	evCheckListDrop($event) {
		const oldIndex = +$event.el.getAttribute('data-checkListIndex');
		const newOrder = +$event.dropIndex;
		const dragElement = this.serviceChecklists[oldIndex];
		this.serviceChecklists.splice(oldIndex, 1);
		this.serviceChecklists.splice(newOrder, 0, dragElement);
	}

	additionalOnChange(additional, checkListIndex, optionIndex) {
		this.serviceChecklists[checkListIndex].options[optionIndex].additional = (additional == 0) ? 1 : 0;
	}
}

