import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IdeateHelper, IdeateValidators } from './services/ideate';
import { GoogleMapSearchComponent } from './components/google-map-search/google-map-search.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChatViewComponent } from './components/chat-view/chat-view.component';
import { pgCardModule } from '../@pages/components/card/card.module';
import { pgSelectModule } from '../@pages/components/select/select.module';
import { CheckboxTrueFalseValueDirective } from './directives/checkbox-true-false-value.directive';
import { FocusInvalidInputDirective } from './directives/focus-invalid-input.directive';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		pgCardModule,
		pgSelectModule,
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyBXjY9wwMauc2t0CzekzOxbkrxJMg2HzKU',
			libraries: ['places']
		})
	],
	declarations: [GoogleMapSearchComponent, TimePickerComponent, ChatViewComponent, CheckboxTrueFalseValueDirective, FocusInvalidInputDirective],
	providers: [IdeateHelper, IdeateValidators, GoogleMapsAPIWrapper],
	exports: [ChatViewComponent, GoogleMapSearchComponent, TimePickerComponent, CheckboxTrueFalseValueDirective, FocusInvalidInputDirective]
})
export class CoreModule { }
