import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

import { IdeateHelper, IdeateValidators as CustomValidators } from '../../core/services/ideate';
import { Account } from '../../providers/account';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: './login.html'
})
export class LoginComponent implements OnInit {
	@ViewChild('siteSelectorModal') siteSelectorModal: ModalDirective;

	public frmLogin: FormGroup;
	public sites: any = {};
	private validationMessages: any = {
		username_email: {
			required: 'Please enter your username/email.'
		},
		password: {
			required: 'Please enter your password.'
		},
	};

	constructor(
		private modalService: BsModalService,
		private router: Router,
		private formBuilder: FormBuilder,
		public helper: IdeateHelper,
		private customValidators: CustomValidators,
		private account: Account) {

	}
	modalRef?: BsModalRef;

	ngOnInit() {

		this.frmLogin = this.formBuilder.group({
			username_email: ['', Validators.compose([Validators.required])],
			password: ['', Validators.compose([Validators.required])]
		});

		this.frmLogin.valueChanges.subscribe((data) => {
			this.validateForm();
		});
		this.frmLogin.statusChanges.subscribe((data) => {
			this.validateForm();
		});
		this.validateForm();

	}

	validateForm() {
		this.validationMessages = this.customValidators.getValidationErrors(this.frmLogin, this.validationMessages);
	}

	login(source = "onSubmit") {

		if (source == 'onSubmit') {
			this.helper.siteToken = null;
		}

		const userInputs: any = this.frmLogin.value;
		const reqParams: any = {};
		reqParams.email = userInputs.username_email;
		reqParams.password = userInputs.password;

		const errorTitle = 'Login Failed!';
		this.helper.makeAPIRequest('account/login', reqParams).then((response) => {

			if (response.success === 1) {
				this.helper.siteToken = response.data.siteToken;
				this.account.logIn(response.data.userdata);
				this.router.navigate(['tickets']);
			} else if (response.error === 1) {
				if (response.errorCode === 3) {
					this.helper.showNotification('danger', 'Email or password not matched, or account is trashed!', errorTitle);
				} else if (response.errorCode === 4) {
					this.helper.showNotification('danger', 'Account status is inactive.', errorTitle);
				} else if (response.errorCode === this.helper.config.siteSelectionErrorCode) {
					// select the site 
					try {
						this.sites = response.data.site;
						this.modalRef = this.modalService.show(this.siteSelectorModal, { class: 'site_selection_modal' });
					} catch (error) {
						console.log(error);
					}
				} else {
					this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, errorTitle);
				}
			} else {
				this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, errorTitle);
			}
		}).catch((httpError) => {
			this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, errorTitle);
		});
	}

	// Handler for site selection
	onSiteSelected(selectedSite: any) {
		this.modalRef.hide(); // Hide the modal after selection
		if (selectedSite) {
			this.helper.siteToken = selectedSite.key;
			this.login("site_selection");
		}
	}
}