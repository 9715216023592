import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

import { IdeateHelper, IdeateValidators as CustomValidators } from '../../core/services/ideate';
import { Account } from '../../providers/account';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';


@Component({
	templateUrl: './reset_password.html'
})
export class ResetPasswordComponent implements OnInit {
	@ViewChild('siteSelectorModal') siteSelectorModal: ModalDirective;

	public logoURL: string;
	public sites: any = {};
	public frmResetPassword: FormGroup;
	private validationMessages: any = {
		email: {
			required: 'Please enter your email.',
			email: 'Please enter an appropriate email.'
		}
	};

	constructor(
		private modalService: BsModalService,
		private router: Router,
		private formBuilder: FormBuilder,
		public helper: IdeateHelper,
		private customValidators: CustomValidators,
		private account: Account) {

	}
	modalRef?: BsModalRef;


	ngOnInit() {

		this.frmResetPassword = this.formBuilder.group({
			email: ['', Validators.compose([Validators.required, Validators.email])],
		});

		this.frmResetPassword.valueChanges.subscribe((data) => {
			this.validateForm();
		});
		this.frmResetPassword.statusChanges.subscribe((data) => {
			this.validateForm();
		});
		this.validateForm();

	}

	validateForm() {
		this.validationMessages = this.customValidators.getValidationErrors(this.frmResetPassword, this.validationMessages);
	}

	reset_password(source = "onSubmit") {

		if (source == 'onSubmit') {
			this.helper.siteToken = null;
		}

		const userInputs: any = this.frmResetPassword.value;

		const reqParams: any = {};
		reqParams.email = userInputs.email;

		this.helper.makeAPIRequest('account/reset_password', reqParams).then((response) => {
			if (response.success === 1) {
				this.helper.showNotification('success', 'Please check your inbox for the new password.', this.helper.config.defaultSuccessTitle);
				this.router.navigate(['login']);
			} else if (response.error === 1) {
				if (response.errorCode === 3) {
					this.helper.showNotification('danger', 'Email doesn\'t exists!', this.helper.config.defaultErrorTitle);
				} else if (response.errorCode === this.helper.config.siteSelectionErrorCode) {
					// select the site 
					try {
						this.sites = response.data.site;
						this.modalRef = this.modalService.show(this.siteSelectorModal, { class: 'site_selection_modal' });
					} catch (error) {
						console.log(error);
					}
				} else if (response.errorCode === 4) {
					this.helper.showNotification('danger', 'Inactive Account!. Please contact the administrator.', this.helper.config.defaultErrorTitle);
				} else {
					this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
				}
			} else {
				this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
			}
		}).catch((httpError) => {
			this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
		});

	}

	// Handler for site selection
	onSiteSelected(selectedSite: any) {
		this.modalRef.hide(); // Hide the modal after selection
		if (selectedSite) {
			this.helper.siteToken = selectedSite.key;
			this.reset_password("site_selection");
		}
	}

}